import classNames from "classnames";
import { useAppState } from "hooks/appState";
import useGtmEvent from "hooks/gtmTrack";
import styles from "./ProductListItem.module.scss";

export default function ProductListItem({ item }) {
  // eslint-disable-next-line no-unused-vars
  const [appState, setAppState] = useAppState();
  const { trackEvent } = useGtmEvent();

  const selectProduct = () => {
    setAppState({ currentProduct: item.id });
    setTimeout(() => setAppState({ swipeBoxVisible: true }), 200);

    trackEvent({
      event: "TileSelected",
      category: "cserép",
      action: "megtekintés",
      label: item.name
    });
  };

  if (!item) {
    return
  }

  return (
    <div
      className={classNames(styles.m, item.is_flat ? styles.flat : styles.waved, "ProductListItem")}
      onClick={selectProduct}>
      <img src={item.tile_image} alt={item.name} onDragStart={event => event.preventDefault()} />
    </div>
  );
}
